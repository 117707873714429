<template>
    <div>
        <div class="week_bg" style="background-image: url(img/athleticsRank/w_bg.png);">
            <div class="week_date_title">{{'活动时间：'+ actDate }}</div>
            <div class="st_rule" style="background-image: url(img/athleticsRank/w_rule.png);" @click="goRule"></div>
            <div class="st_last_week_rank" style="background-image: url(img/athleticsRank/last_week.png);"
                @click="goLastWeekRank"></div>
            <!-- 活动进行中 -->

        </div>
        <div class="week_rank_father" style="background-image: url(img/athleticsRank/w_bg_other.png);">
            <div class="week_gift_title" style="background-image: url(img/athleticsRank/week_gift_title.png);"></div>
            <div class="week_gift display_flex justify-content_flex-justify align-items_center"
                style="background-image: url(img/athleticsRank/week_gift_bg.png);">
                <!-- <div v-if="loading" class="w_loading">加载中...</div> -->
            </div>
            <div class="week_rank_title" style="background-image: url(img/athleticsRank/week_rank_title.png);"> </div>
            <div :class="list.length == 0 ? 'week_rank_nodata' : 'week_rank'"
                :style="list.length == 0 ? 'background-image: url(img/athleticsRank/list_bg_nodata.png);' : 'background-image: url(img/athleticsRank/list_bg.png);'">
                <div class="nav_container" style="position:relative;margin-top:50px;z-index:99999">
                    <div class="week_nav_border" v-for="(item, index) in nav" :key="index" @click="changeNav(index)"
                        :style="{ backgroundImage: navCur == index ? `url(img/athleticsRank/border_active.png)` : `url(img/athleticsRank/border_default.png)` }">
                        <div class="week_nav"
                            :style="{ backgroundImage: navCur == index ? `url(img/athleticsRank/navActive${index}.png)` : `url(img/athleticsRank/nav${index}.png)` }">
                        </div>
                    </div>

                </div>
                <!-- <div v-if="loading" class="w_loading">加载中...</div> -->
                <!-- 缺少v-if，记得补上 -->
                <div class="noData" v-if="noData">
                    <img src="img/noData.png" alt="">
                </div>
                <div class="week_top" v-if="oneData">
                    <!-- 第一名 -->
                    <div style="background-image:url('img/athleticsRank/top_one_bg.png');"
                        class="week_one display_flex flex-direction_column align-items_center" v-if="oneData">
                        <div class="top-one">
                            <img class="sub_big" src="img/athleticsRank/gold.png" alt="">
                            <img :src="oneData.head_portrait" alt="">
                            <div class="week_name display_flex justify-content_flex-center align-items_center">
                                <p>{{ oneData.nickname }}</p>
                            </div>
                            <div class="week_id">ID:{{ oneData.uid }}</div>
                            <!-- <div class="top_one_bg" style="background-image:url('img/athleticsRank/top_one_bg.png');"></div> -->
                        </div>

                    </div>
                    <!-- 第二名 -->
                    <div style="background-image:url('img/athleticsRank/top_two_bg.png');"
                        class="week_two display_flex flex-direction_column align-items_center" v-if="twoData">
                        <div class="top-two">
                            <img class="sub" src="img/athleticsRank/silver.png" alt="">
                            <img :src="twoData.head_portrait" alt="">
                            <div class="week_nickname">{{ twoData.nickname }}</div>
                            <div class="week_id">ID:{{ twoData.uid }}</div>
                            <div class="week_value">距上名<span style="color:#ff2079;font-size:12px;">{{ twoData.diff_score
                            }}</span></div>
                        </div>

                        <!-- <div class="top_two_bg" style="background-image:url('img/athleticsRank/top_two_bg.png');"></div> -->
                    </div>
                    <!-- 第三名 -->
                    <div style="background-image:url('img/athleticsRank/top_three_bg.png');"
                        class="week_third display_flex flex-direction_column align-items_center" v-if="thirdData">
                        <div class="top-three">
                            <img class="sub" src="img/athleticsRank/bronze.png" alt="">
                            <img :src="thirdData.head_portrait" alt="">
                            <div class="week_nickname">{{ thirdData.nickname }}</div>
                            <div class="week_id">ID:{{ thirdData.uid }}</div>
                            <div class="week_value">距上名<span style="color:#ff2079;font-size:12px;margin-left:4px">{{
                                thirdData.diff_score }}</span></div>
                        </div>

                        <!-- <div class="top_three_bg" style="background-image:url('img/athleticsRank/top_three_bg.png');"></div> -->
                    </div>
                </div>
                <!-- 第四名往后 -->
                <div class="week_listMg">
                    <div class="week_view display_flex justify-content_flex-justify align-items_center"
                        v-for="(item, index) in list" :key="index">
                        <div class="display_flex align-items_center">
                            <div class="week_three">{{ index + 4 }}</div>
                            <img class="week_head" :src="item.head_portrait" alt="">
                            <div class="week_people">
                                <p>{{ item.nickname }}</p>
                                <span>ID:{{ item.uid }}</span>
                            </div>
                        </div>
                        <div class="week_cp margin-right-sm">
                            <span>距上一名</span>
                            <p>{{ item.diff_score }}</p>

                        </div>
                    </div>
                    <div class="week_my" v-if="my_diff_score > 0">-我距上榜差：<span style="color: #ff2079;">{{ my_diff_score }}</span> 积分-</div>
                </div>
              
                <!-- 自己排行 -->
                <!-- 无数据 -->
                <!-- <div class="w_noData" v-if="noData">
                    <img src="img/athleticsRank/none.png" alt="">
                </div> -->
            </div>
            <div class="bottom_tips">本活动最终解释权归多乐所有</div>
        </div>

      
        <!-- <div class="week_beself" style="background-image: url(img/athleticsRank/week_bottom.png);"></div> -->


    </div>
</template>
  
<script>
import "@/assets/css/base.css"
import "@/assets/css/athletics.css"
import { gameRankList } from "@/api/ranking";

export default {
    name: 'athleticsRank',
    data() {
        return {
            nav: [
                {
                    name: '风云榜'
                },
                {
                    name: '战神榜'
                }],
            navCur: 0,
            list: [],
            giftInfo: [],
            loading: true,
            my_diff_score: null,
            noData: false,
            oneData: null,
            twoData: null,
            thirdData: null,
            actDate: null
        }
    },
    created() {
        let access_token = this.$route.query.access_token
        localStorage.setItem('access_token', access_token)
        this.getData()
    },
    methods: {
        getData() {
            let type = this.navCur + 1
            let week = 1
            gameRankList({ type, week }).then(response => {
                this.loading = false
                let [oneData = null, twoData = null, thirdData = null, ...list] = response.data.data
                this.oneData = oneData
                this.twoData = twoData
                this.thirdData = thirdData
                this.list = list
                this.actDate = response.data.date
                this.my_diff_score = response.data.my_diff_score
                if (response.data.data.length == 0) {
                    this.noData = true
                } else {
                    this.noData = false
                }
            },
                error => {
                    this.loading = false
                    this.noData = true
                    this.$toast.fail(error.msg)
                }).catch((error) => {
                    this.loading = false
                    this.noData = true
                    this.$toast.fail(error.msg)
                })
        },
        changeNav(e) {
            this.navCur = e
            this.getData()
        },
        goRule() {
            this.$router.push({ path: '/rank/athleticsRule', query: { date: this.actDate } })
        },
        goLastWeekRank() {
            this.$router.push({ path: '/rank/athleticsLastWeekRank', query: { date: this.actDate } })
        },
        goBack() {
            this.$router.go(-1)
        }
    }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
  